import DashboardLayout from "@/pages/layouts/DashboardLayout";

let industryRoutes = {
  path: '/industries',
  component: DashboardLayout,
  redirect: '/industries/list',
  children: [
    {
      path: 'list',
      name: 'Industry List',
      component: () => import(/* webpackChunkName: "industries" */  '@/pages/industries/IndustryList'),
      meta: {
        permissionsCodes: ['industries/index' ],
      }
    },
    {
      path: 'create',
      name: 'Add Industry',
      component: () => import(/* webpackChunkName: "industries" */  '@/pages/industries/IndustryForm'),
      meta: {
        permissionsCodes: ['industries/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Industry',
      component: () => import(/* webpackChunkName: "industries" */  '@/pages/industries/IndustryForm'),
      meta: {
        permissionsCodes: ['industries/update' ],
      }
    }
  ]
}

export default industryRoutes;
